@tailwind base;
@tailwind components;
@tailwind utilities;

html {
	overflow: hidden;
	height: 100%;
}

body {
	margin: 0;
	font-family: 'Titillium Web', 'Helvetica', Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100%;
	height: 100%;
	min-height: 100vh;
	overflow: scroll;
	/* pointer-events: none; */
}

#root {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 100%;
	overflow: scroll;
	/* pointer-events: none; */
}
